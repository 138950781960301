import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`TODO List`}</h1>
    <p>{`List of topics to potentially be included on the Wiki...`}</p>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`comparison to other daws`}<ul parentName="li">
          <li parentName="ul">{`ableton`}</li>
          <li parentName="ul">{`flstudio`}</li>
          <li parentName="ul">{`could be useful to create a summary table / "cheat sheet" with the major differences
between each of of the major daws.`}</li>
        </ul></li>
      <li parentName="ul">{`getting help`}</li>
      <li parentName="ul">{`contributing`}<ul parentName="li">
          <li parentName="ul">{`basic tutorial on how to use clone repo, make change, push them, submit a PR, and
then pull upstream change later on.`}</li>
        </ul></li>
      <li parentName="ul">{`bitwig-specific features`}</li>
      <li parentName="ul">{`missing features`}</li>
      <li parentName="ul">{`producers using bitwig`}</li>
    </ul>
    <h2>{`Bitwig`}</h2>
    <p>{`Where possible, it may be useful to structure the Bitwig wiki in a similar manner to how
things are organized in Bitwig itself, or in the Bitwig manual.`}</p>
    <p>{`When creating topic-specific pages such as those below, one could consider linking to
relevant Youtube tutorials to make it easy for users to find more information on each
topic.`}</p>
    <ul>
      <li parentName="ul">{`audio`}<ul parentName="li">
          <li parentName="ul">{`audio clips/events`}</li>
          <li parentName="ul">{`bouncing`}</li>
          <li parentName="ul">{`recording`}</li>
        </ul></li>
      <li parentName="ul">{`instruments`}<ul parentName="li">
          <li parentName="ul">{`polysynth`}</li>
          <li parentName="ul">{`fm4`}</li>
        </ul></li>
      <li parentName="ul">{`effects`}<ul parentName="li">
          <li parentName="ul">{`each effect could have its own page with an overview + tips & tricks / recipes / etc.`}</li>
          <li parentName="ul">{`another useful effects-related page could be an (opinionated) overview of which
bitwig stock effects are good on their own, and which ones are not-so-great, for
which users may want to look to third-party vsts.`}</li>
        </ul></li>
      <li parentName="ul">{`automation`}</li>
      <li parentName="ul">{`midi`}</li>
      <li parentName="ul">{`modulation`}</li>
      <li parentName="ul">{`grid`}</li>
      <li parentName="ul">{`keyboard shortcuts`}</li>
      <li parentName="ul">{`settings`}</li>
      <li parentName="ul">{`ui`}</li>
      <li parentName="ul">{`templates`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      